import {
  AgentWorkspaceConstants,
  fetchActivityTypesForEntityType,
} from "lookup/AgentWorkspaceConstants";
import React, { useEffect, useRef, useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { FaInfoCircle } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import DismissableModal from "components/DismissableModal";
import { FormSelect } from "react-bootstrap";
import * as Yup from "yup";
import { getErrorMessage } from "services/UtilityService";
import { Formik } from "formik";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { showNotification } from "services/NotificationService";
import { withRouter } from "react-router-dom";
import { prescriberEntityType, taskTypes } from "./CreateActivityConstants";
import ButtonWithPopover from "components/ButtonWithPopover";

const CreateActivity = (props) => {
  const [wfItemId] = useState(props.wfItemId);
  const {
    isMemberActivityCreatable,
    contextIsRaw,
    history,
    disableButton,
    selectedSwitches,
    contextIsAW,
    selectedPrescriberUuids,
    selectedPrescriberNames,
  } = props;
  const title = "Create Activity for Selected Switch(es)";
  const modalRef = useRef("CreateActivtyModal");

  const [activityData, setActivityData] = useState([]);

  useEffect(() => {
    if (selectedPrescriberUuids && selectedPrescriberUuids.length > 0) {
      sendAuthenticatedAsyncRequest(
        `/agent-workspace/switches-with-open-call-activities?prescriberInfoUuids=${encodeURIComponent(
          selectedPrescriberUuids.join(",")
        )}`,
        "GET",
        null,
        (r) => {
          setActivityData(r.data);
        },
        (e) => {
          showNotification({
            title: `Unable to Fetch Relevant Activities!`,
            message:
              getErrorMessage(e.data) ||
              getErrorMessage("SOMETHING_WENT_WRONG"),
            position: "tr",
            type: "error",
          });
        }
      );
    }
  }, [selectedPrescriberUuids]);
  const createActivitySchema = Yup.object().shape(
    {
      entityType: Yup.string()
        .oneOf(AgentWorkspaceConstants.entityTypeFilter)
        .required(),
      activityType: Yup.string()
        .oneOf(AgentWorkspaceConstants.activityTypeFilter)
        .required(),
      note: Yup.string().max(4096, "Max Length: 4096").nullable(true),
    },
    [["entityType", "activityType", "note"]]
  );

  const submitForm = React.useCallback((originalValues, setSubmitting) => {
    sendAuthenticatedAsyncRequest(
      `/agent-workspace/create-activity/${
        wfItemId ? wfItemId : selectedSwitches[0]
      }`,
      "POST",
      { ...originalValues, scheduleDate: null, selectedSwitches },
      (r) => {
        showNotification({
          title: `Activity Creation Successful`,
          message: `${originalValues.activityType} ${originalValues.entityType} activity created!`,
          position: "tr",
          type: "success",
        });
        setSubmitting(false);
        modalRef.current.handleClose();

        if (contextIsRaw || contextIsAW) {
          history.goBack();
        } else {
          props.fetchData();
        }
      },
      (e) => {
        showNotification({
          title: `Unable to create Activity!`,
          message:
            getErrorMessage(e.data) || getErrorMessage("SOMETHING_WENT_WRONG"),
          position: "tr",
          type: "error",
        });
        setSubmitting(false);
      }
    );
  });

  return (
    <DismissableModal
      key={title}
      ref={modalRef}
      openModalButtonText="Create Activity"
      openModalButtonBlock={true}
      openModalButtonStyle={
        props.openModalButtonStyle ? props.openModalButtonStyle : "primary"
      }
      openModalButtonDisabled={disableButton}
      title={title}
      size="lg"
      hideFooter={true}
    >
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            entityType: "",
            activityType: "",
            unlinkFax: false,
            note: "",
          }}
          onSubmit={React.useCallback((values, { setSubmitting }) => {
            submitForm(values, setSubmitting);
          })}
          validationSchema={createActivitySchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Entity Type</Form.Label>
                      <FormSelect
                        value={values.entityType}
                        onChange={(e) =>
                          setFieldValue("entityType", e.target.value)
                        }
                        name="entityType"
                        onBlur={handleBlur}
                      >
                        <option value="">Select Entity Type</option>
                        {isMemberActivityCreatable
                          ? AgentWorkspaceConstants.entityTypeFilter.map(
                              (entityType, i) => (
                                <option key={entityType} value={entityType}>
                                  {entityType}
                                </option>
                              )
                            )
                          : AgentWorkspaceConstants.basicEntityTypeFilter.map(
                              (entityType, i) => (
                                <option key={entityType} value={entityType}>
                                  {entityType}
                                </option>
                              )
                            )}
                      </FormSelect>
                      <Form.Text className="text-small text-danger">
                        {errors.entityType &&
                          touched.entityType &&
                          errors.entityType}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Activity Type</Form.Label>
                      <FormSelect
                        value={values.activityType}
                        onChange={(e) =>
                          setFieldValue("activityType", e.target.value)
                        }
                        name="activityType"
                        onBlur={handleBlur}
                      >
                        <option value="">Select Activity Type</option>

                        {fetchActivityTypesForEntityType(values.entityType).map(
                          (activityType, i) => (
                            <option key={activityType} value={activityType}>
                              {activityType}
                            </option>
                          )
                        )}
                      </FormSelect>
                      <Form.Text className="text-small text-danger">
                        {errors.activityType &&
                          touched.activityType &&
                          errors.activityType}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                {contextIsRaw && (
                  <Row className="top-spacer">
                    <Alert variant="warning">
                      <Alert.Heading as="h6">
                        <Form.Group>
                          <Form.Check
                            label="Unlink Fax"
                            checked={values.unlinkFax}
                            onChange={() =>
                              setFieldValue("unlinkFax", !values.unlinkFax)
                            }
                            id="unlinkFax"
                            name="unlinkFax"
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </Alert.Heading>
                      <p style={{ display: "flex", alignItems: "center" }}>
                        <FaInfoCircle />{" "}
                        <span>
                          &nbsp; If fax is unlinked, the switch state will
                          return to <i>Awaiting Response</i>
                        </span>
                        .
                      </p>
                    </Alert>
                  </Row>
                )}
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.note}
                        name="note"
                        as="textarea"
                        data-testid="input-create-activity-notes"
                      />
                      <Form.Text className="text-small text-danger">
                        {errors.note && touched.note && errors.note}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                {values.entityType === prescriberEntityType &&
                taskTypes.includes(values.activityType) &&
                activityData.length > 0 ? (
                  <Row className="top-spacer">
                    <Col md={2} className="bottom-spacer">
                      <ButtonWithPopover
                        placement="auto"
                        customRef={"closeConfimationBwp"}
                        buttonClassName="btn-text-black"
                        buttonVariant="success"
                        buttonText="Create"
                        buttonIsDisabled={isSubmitting}
                        popoverTitle="Close Call Activities"
                        dataTestId="btn-close-activity-submit"
                      >
                        {selectedPrescriberNames.join(", ")} has New/Open Call
                        activities.
                        <ul>
                          {activityData.map((item) => (
                            <li key={item.activityUuid}>
                              {item.concatenatedInfo}
                            </li>
                          ))}
                        </ul>
                        Creating this task will close the call activities. Would
                        you like to create the task?
                        <div className="align-content-right">
                          <Button
                            type="submit"
                            variant="success"
                            block="true"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                          >
                            Yes
                          </Button>
                        </div>
                      </ButtonWithPopover>
                    </Col>
                  </Row>
                ) : (
                  <Row className="top-spacer">
                    <Col md={4} className="bottom-spacer">
                      <Button
                        type="submit"
                        variant="success"
                        block="true"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Creating ... " : "Create"}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </DismissableModal>
  );
};

export default withRouter(CreateActivity);
