import MultiSelect from "multiselect-react-dropdown";
import React, { useRef } from "react";

const MultiSelectFilter = ({ column, handleFilterChange, filterValue }) => {
  const { id } = column;

  const multiSelectRef = useRef(null);
  filterValue = filterValue || [];
  if (!Array.isArray(filterValue)) {
    filterValue = [filterValue];
  }
  var filterNormed = [];
  if (column.filterOptions.length > 0) {
    filterValue.forEach((state, i) => {
      if (typeof state === "string") {
        filterNormed.push(
          column.filterOptions.filter(
            (element) => element.id === state || element.name === state
          )[0]
        );
      } else {
        filterNormed.push(state);
      }
    });
  }
  if (filterValue.length === 0) {
    multiSelectRef?.current?.resetSelectedValues();
  }
  filterValue = filterNormed;

  const onChange = (selectedOptions) => {
    handleFilterChange(id, selectedOptions, column);
    multiSelectRef?.current?.searchBox?.current?.blur();
  };


  return (
    <MultiSelect
      style={{
        inputField: {
          width: column.size,
        },
        optionContainer: {
          width: "fit-content",
          background: "white",
          height: "fit-content",
          maxHeight: "fit-content",
          overflowX: "hidden",
          overflowY: "hidden",
        },
      }}
      ref={multiSelectRef}
      key={`${column.id}Filter`}
      id={`multiselect${id}`}
      options={column.filterOptions}
      selectedValues={filterValue}
      onSelect={onChange}
      closeOnSelect={true}
      onRemove={(selectedOptions) =>
        handleFilterChange(id, selectedOptions, column)
      }
      displayValue="name"
      closeIcon="cancel"
      placeholder=""
      disable={column.isDisabled}
    />
  );
};

export default MultiSelectFilter;
