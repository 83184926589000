import MultiSelect from "multiselect-react-dropdown";
import React, { useRef } from "react";
import { SELECT_ALL } from "../Constants";

const MultiSelectFilterWithAll = ({ column, handleFilterChange, filterValue }) => {
  const { id } = column;

  const multiSelectRef = useRef(null);
  filterValue = filterValue || [];
  if (!Array.isArray(filterValue)) {
    filterValue = [filterValue];
  }
  var filterNormed = [];
  if (column.filterOptions.length > 0) {
    filterValue.forEach((state, i) => {
      if (typeof state === "string") {
        filterNormed.push(
          column.filterOptions.filter(
            (element) => element.id === state || element.name === state
          )[0]
        );
      } else {
        filterNormed.push(state);
      }
    });
  }
  if (filterValue.length === 0) {
    multiSelectRef?.current?.resetSelectedValues();
  }
  filterValue = filterNormed;


  const handleSelect = (selectedList, selectedItem) => {
    if (selectedItem.id === SELECT_ALL) {
      onChange(column.filterOptions);
    } else {
      onChange(selectedList);
    }
  };


  const onChange = (selectedOptions) => {
    handleFilterChange(id, selectedOptions, column);
    multiSelectRef?.current?.searchBox?.current?.blur();
  };

  const onRemoveHandler = (selectedOptions, excludedItem) => { 
    const excluded = excludedItem || {};
    if (excluded?.id === SELECT_ALL) {
      handleFilterChange(id, [], column);
    } else {
        selectedOptions = selectedOptions.filter((option) => option.id !== SELECT_ALL);
        handleFilterChange(id, selectedOptions, column)
    }
  }


  return (
    <MultiSelect
      style={{
        inputField: {
          width: column.size,
        },
        optionContainer: {
          width: "fit-content",
          background: "white",
          height: "fit-content",
          maxHeight: "fit-content",         
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
      ref={multiSelectRef}
      key={`${column.id}Filter`}
      id={`multiselect${id}`}
      options={column.filterOptions}
      selectedValues={filterValue}
      onSelect={handleSelect}
      closeOnSelect={true}
      onRemove={(selectedOptions, removedValue) => {
        onRemoveHandler(selectedOptions, removedValue)
      }
    }
      displayValue="name"
      closeIcon="cancel"
      placeholder=""
      disable={column.isDisabled}
    />
  );
};

export default MultiSelectFilterWithAll;
