import { ColumnTypes, FilterTypes, SELECT_ALL } from "commons/table/Constants";
import DisplayBar from "commons/table/DisplayBar/DisplayBar";
import ServerSideReactTable from "commons/table/ServerSideReactTable";
import {
  renderAllSwitchesCell,
  renderAWStatusFilterValue,
  renderScheduledDateCol,
} from "components/ReactTableColumnHelper";
import { WrapTextAndJustifyCenter } from "components/WrapText";
import { debounce, isEqual } from "lodash";
import { AgentWorkspaceConstants } from "lookup/AgentWorkspaceConstants";
import Colors from "lookup/Colors.json";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { sendAuthenticatedAsyncRequest } from "services/AsyncRequestService";
import { isLoggedInUserAWAdmin, isLoggedInUserBioSimilarUser } from "services/AWUtilityService";
import { showNotification } from "services/NotificationService";
import {
  extractDefaultFilters,
  getErrorMessage,
  getPageSize,
  isCollectionNullOrEmpty,
  isNull
} from "services/UtilityService";
import {
  resetError,
  updateCallGroupClaimer,
  updateCallGroupIndexOnList,
  updateCallGroupList,
  updateCallGroupListState,
  updateIsShowBioSimilarToggle,
} from "./CallGroup.actions";

const CallGroupList = (props) => {
  const ref = useRef(true);
  const [filters, setFilters] = useState(null);
  const [defaultSorts, setDefaultSorts] = useState([
    AgentWorkspaceConstants.DEFAULT_SORT,
  ]);
  const [filterSelectMap, setFilterSelectMap] = useState({});
  const [defaultFilters, setDefaultFilters] = useState([]);
  const isBioSimilarToggle = useSelector(
    (state) => state.callGroupList.isShowBioSimilar
  );
  const location = useLocation();
  const [columns, setColumns] = useState([]);
  const [resetToggled, setResetToggled] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.callGroupList.loading);
  const data = useSelector((state) =>
    state.callGroupList.data !== undefined ? state.callGroupList.data : []
  );
  const pages = useSelector((state) =>
    state.callGroupList.pages ? state.callGroupList.pages : 0
  );
  const pageSizeValue = useSelector((state) =>
    state.callGroupList.pageSize ? state.callGroupList.pageSize : 0
  );
  const CALL_GROUP_LIST_ROW_COUNT = "CALL_GROUP_LIST_ROW_COUNT";
  const pageSize = getPageSize(
    AgentWorkspaceConstants.DEFAULT_PAGE_SIZE,
    pageSizeValue,
    CALL_GROUP_LIST_ROW_COUNT
  );
  const totalElements = useSelector((state) =>
    state.callGroupList.totalElements !== undefined
      ? state.callGroupList.totalElements
      : "Loading ..."
  );
  const handleBioSimilarToggle = (event) => {
    let updatedFilters = [...listState.filtered];
    if (event.target.checked) {
      updatedFilters = updatedFilters.filter(
        (element) => element.id !== "priorityAgent"
      );

      updatedFilters = updatedFilters.filter(
        (element) => element.id !== "clientNameAbbreviation"
      );
      updatedFilters = updatedFilters.filter(
        (element) => element.id !== "customerNameAbbreviation"
      );

      updatedFilters.push(
        { id: "priorityAgent", value: true },
        { id: "customerNameAbbreviation", value: "WMT" },
        { id: "clientNameAbbreviation", value: "WMT" }
      );
    } else {
      updatedFilters = updatedFilters.filter(
        (element) => element.id !== "priorityAgent"
      );
      updatedFilters = updatedFilters.filter(
        (element) => element.id !== "clientNameAbbreviation"
      );
      updatedFilters = updatedFilters.filter(
        (element) => element.id !== "customerNameAbbreviation"
      );
      updatedFilters.push({ id: "priorityAgent", value: false });
    }

    dispatch(updateIsShowBioSimilarToggle(event.target.checked));
    const updatedListState = {
      ...listState,
      filtered: updatedFilters,
    };

    updateData(updatedListState);
  };

  useEffect(() => {
    if (resetToggled) {
      let bioSimilarBoolean = false;

      if (isLoggedInUserBioSimilarUser() && !isLoggedInUserAWAdmin()) {
        bioSimilarBoolean = true;
      }
      bioSimilarToggleRelevantFunctionality(bioSimilarBoolean);
      dispatch(updateIsShowBioSimilarToggle(bioSimilarBoolean));
      setResetToggled(false);
    }
  }, [resetToggled]);

  const reset = () => {
    setResetToggled(true);
  };

  const isError = useSelector((state) => state.callGroupList.isError);
  const listState = useSelector((state) =>
    state.callGroupList.listState !== undefined
      ? state.callGroupList.listState
      : {
          page: 0,
          pageSize: AgentWorkspaceConstants.DEFAULT_PAGE_SIZE,
          sorted: defaultSorts || [],
          filtered: defaultFilters || [],
          resized: [],
          expanded: {},
        }
  );

  const showFilterAlter =
    listState?.filtered?.some((f) =>
      AgentWorkspaceConstants.CUSTOMER_CLIENT_FILTER_KEYS.includes(f.id)
    ) && data.length === 0;

  useEffect(() => {
    if (ref.current && isBioSimilarToggle === undefined) {
      let bioSimilarBoolean = undefined;
      if (isLoggedInUserBioSimilarUser() && !isLoggedInUserAWAdmin()) {
        bioSimilarBoolean = true;
      } else {
        bioSimilarBoolean = false;
      }
      bioSimilarToggleRelevantFunctionality(bioSimilarBoolean);
      dispatch(updateIsShowBioSimilarToggle(bioSimilarBoolean));
    }
  }, []);

  const bioSimilarToggleRelevantFunctionality = (bioSimilarToggle) => {
    if (typeof bioSimilarToggle !== "undefined") {
      sendAuthenticatedAsyncRequest(
        `/agent-workspace/call-group/list-filters?showBioSimilar=${bioSimilarToggle}`,
        "GET",
        null,
        (response) => {
          const defFilters = extractDefaultFilters(response.data.filters);
          setFilters(response.data.filters);
          setDefaultSorts(response.data.sorts);
          setDefaultFilters(defFilters);
          if (isCollectionNullOrEmpty(listState.filtered)) {
            updateData(
              {
                ...listState,
                filtered: defFilters,
                sorted: response.data.sorts,
              },
              true
            );
          } else {
            if (resetToggled) {
              updateData({
                ...listState,
                filtered: defFilters,
                sorted: response.data.sorts,
              });
            } else {
              updateData(listState);
            }
          }
        },
        (error) => {
          showNotification({
            title: "Error fetching Filter Values!",
            message: "Something went Wrong.",
            position: "tr",
            type: "error",
          });
          dispatch(resetError());
        }
      );
    }
  };

  useEffect(() => {
    if (!filters) return;
  
    Object.keys(filters).forEach((colKey) => {
      filterSelectMap[colKey] = filters[colKey].map((row) => {
        return { id: row["id"], name: row["value"] };
      });
    });
  
    setFilterSelectMap(filterSelectMap);
  
    const appliedCustomerFilters = listState.filtered.some((e) => e.id === "customerNameAbbreviation")
      ? listState.filtered.find((e) => e.id === "customerNameAbbreviation").value
      : [];
  
    const extractedCustomers = filterSelectMap["customerClientMapping"].filter((e) =>
      appliedCustomerFilters.includes(e.id)
    );
  
    const extractedClients = extractedCustomers.reduce((result, entry) => {
      return result.concat(entry.name);
    }, []);
      
    if (extractedClients.length > 0) {
      filterSelectMap["clientNameAbbreviation"] = filterSelectMap["clientNameAbbreviation"].filter((e) =>
        extractedClients.includes(e.id)
      );
        
      let isFilterValueChanged = false;
  
      const filteredFilters = listState.filtered.map((filter) => {
        if (filter.id === "clientNameAbbreviation" && Array.isArray(filter.value)) {
          const filteredValue = filter.value.filter((val) => extractedClients.includes(val));
          if (!isEqual(filteredValue, filter.value)) {
            isFilterValueChanged = true;
          }
          return { ...filter, value: filteredValue };
        }
        return filter;
      });
  
      if (isFilterValueChanged) listState.filtered = filteredFilters;
    }
  }, [filters, listState.filtered]);


  useEffect(() => {
    bioSimilarToggleRelevantFunctionality(isBioSimilarToggle);
  }, [location.key]);

  useEffect(() => {
    const firstRender = ref.current;
    if (firstRender) {
      ref.current = false;
    }
  }, [ref.current]);
  
  useEffect(() => {
    let cols = [
      {
        header: "Customer",
        accessor: "customerNameAbbreviation",
        accessorKey: "customerNameAbbreviation",
        size: 60,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.MultiSelect,
        filterOptions: filterSelectMap["customerNameAbbreviation"]
          ? filterSelectMap["customerNameAbbreviation"]
          : [],
      },
      {
        header: "Client",
        accessor: "clientNameAbbreviation",
        accessorKey: "clientNameAbbreviation",
        size: 60,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.MultiSelectAll,
        filterOptions: filterSelectMap["clientNameAbbreviation"]
          ? [
            { id: SELECT_ALL, name: SELECT_ALL },
            ...filterSelectMap["clientNameAbbreviation"],
          ]
          : [],
      },
      {
        header: "Switches",
        accessor: "aggregatedSwitchList",
        accessorKey: "aggregatedSwitchList",
        size: 90,
        enableSorting: false,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.SearchableInput,
        customComponent: (value, row) => {
          return renderAllSwitchesCell(row.original.aggregatedSwitchList);
        },
      },
      {
        header: "Open Switches",
        accessor: "openActivitiesCount",
        accessorKey: "openActivitiesCount",
        size: 60,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Text,
      },
      {
        header: "Rank",
        accessor: "priority",
        accessorKey: "priority",
        size: 50,
        enableSorting: true,
        enableColumnFilter: false,
        columnType: ColumnTypes.Text,
      },
      {
        header: "Assigned To",
        accessor: "assigneeName",
        accessorKey: "assigneeName",
        size: 90,
        enableSorting: false,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Claimed By",
        accessor: "claimerName",
        accessorKey: "claimerName",
        size: 90,
        enableSorting: false,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Scheduled For",
        accessor: "scheduledDate",
        accessorKey: "scheduledDate",
        size: 160,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.DateRangePicker,
        customComponent: (value, row) => {
          return renderScheduledDateCol(
            row.original.scheduledDate,
            row.original.daysToScheduledDate
          );
        },
      },
      {
        header: "Days to Scheduled Date",
        accessor: "daysToScheduledDate",
        accessorKey: "daysToScheduledDate",
        size: 200,
        show: false,
        enableSorting: false,
        enableColumnFilter: false,
        columnType: ColumnTypes.DateColumn,
      },
      {
        header: "Entity",
        accessor: "entityType",
        accessorKey: "entityType",
        size: 100,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.MultiSelect,
        filterOptions: filterSelectMap["entityType"]
          ? filterSelectMap["entityType"]
          : [],
      },
      {
        header: "Name",
        accessor: "entityName",
        accessorKey: "entityName",
        size: 90,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Phone #",
        accessor: "phoneNumber",
        accessorKey: "phoneNumber",
        size: 70,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.SearchableInput,
      },
      {
        header: "Time Zone",
        accessor: "timeZone",
        accessorKey: "timeZone",
        size: 60,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.MultiSelect,
        filterOptions: filterSelectMap["timeZone"]
          ? filterSelectMap["timeZone"]
          : [],
      },
      {
        header: "Type",
        accessor: "type",
        accessorKey: "type",
        size: 70,
        enableSorting: false,
        enableColumnFilter: true,
        columnType: ColumnTypes.Text,
        filterType: FilterTypes.MultiSelect,
        filterOptions: filterSelectMap["type"]
          ? filterSelectMap["type"]
          : [],
      },
      {
        header: "Status",
        accessor: "status",
        accessorKey: "status",
        size: 120,
        enableSorting: false,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.MultiSelect,
        filterOptions: filterSelectMap["status"]
          ? filterSelectMap["status"]
          : [],
        customComponent: (value, row) => {
          return renderStatus(row);
        },
      },
    ];
    setColumns(cols);
  }, [filters, listState.filtered, filterSelectMap]);

  const renderStatus = (row) => {
    return row.original.escalatedNotes ? (
      <OverlayTrigger
        overlay={
          <Tooltip>
            {row.original.escalatedNotes.split("||").map((note) => {
              return <p key={note}>{note}</p>;
            })}
          </Tooltip>
        }
        placement="top"
      >
        <div>
          <WrapTextAndJustifyCenter>
            {renderAWStatusFilterValue(listState.filtered, row)}
          </WrapTextAndJustifyCenter>
        </div>
      </OverlayTrigger>
    ) : (
      <div>
        <WrapTextAndJustifyCenter>
          {renderAWStatusFilterValue(listState.filtered, row)}
        </WrapTextAndJustifyCenter>
      </div>
    );
  };

  const getRowStyle = (rowInfo) => {
    const applyStyle =
      !isNull(rowInfo) && rowInfo.original.containsNewActivities;
    const rowColor = applyStyle ? Colors["rm-gray-shadow"] : Colors["rm-white"];
    const textColor = applyStyle ? Colors["rm-black"] : Colors["rm-gray-dark"];
    return { background: rowColor, color: textColor };
  };

  const debounceUpdateData = debounce((listState) => {
    updateData(listState, false);
  }, 200);

  const updateData = (state, preventUpdate) => {
    AgentWorkspaceConstants.CALL_GROUP_LIST_MULTI_FILTER_IDS.forEach((item) => {
      const index = state.filtered.findIndex((element) => element.id === item);
      if (index !== -1 && Array.isArray(state.filtered[index].value)) {
        state.filtered[index].value = state.filtered[index].value.map((value) =>
          typeof value === "string" ? value : value.name
        );
      }

    });
    if (!preventUpdate) {
      dispatch(
        updateCallGroupList(
          state.pageSize,
          state.page,
          state.sorted,
          state.filtered,
          state.resized,
          state.expanded
        )
      );
    }
    dispatch(updateCallGroupListState(state));
  };

  const onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    listState.pageSize = pageSize;
    listState.page = pageNo;
    listState.sorted = sortBy ? sortBy : [];
    listState.filtered = filterValues;
    updateData(listState, false);
  };

  const handleRowClick = async (rowInfo) => {
    const isCallGroupClaimed = await checkIfCallGroupClaimed(
      rowInfo.original.callGroupId
    );
    if (!isCallGroupClaimed) {
      dispatch(updateCallGroupIndexOnList(rowInfo.index));
      const status =
        listState.filtered.length !== 0 &&
        listState.filtered.filter((e) => e.id === "status").length > 0
          ? listState.filtered.filter((e) => e.id === "status")[0].value
          : null;
      props.history.push({
        pathname: `/agent-workspace/call-group-details/${rowInfo.original.callGroupId}`,
        state: { status: status },
      });
    }
  };

  const checkIfCallGroupClaimed = async (callGroupId) => {
    const response = await new Promise((resolve, reject) => {
      sendAuthenticatedAsyncRequest(
        `/agent-workspace/${callGroupId}/is-callgroup-claimed`,
        "GET",
        null,
        (r) => {
          resolve(r);
        },
        (r) => {
          showNotification({
            title: "Unable to claim this call group!",
            message:
              getErrorMessage(r.data) ||
              getErrorMessage("SOMETHING_WENT_WRONG"),
            position: "tr",
            type: "error",
          });
          dispatch(
            updateCallGroupClaimer(
              data,
              callGroupId,
              r.data.additionalErrorInfo[0]
            )
          );
          return true;
        }
      );
    });
  };


  useEffect(() => {
    if (isError === true) {
      showNotification({
        title: "Error Loading Data!",
        message: "Something went Wrong.",
        position: "bl",
        type: "error",
      });
      dispatch(resetError());
    }
  }, [isError]);

  return (
    <Container className="list-container" fluid>
      {showFilterAlter ? (
        <Row>
          <Col md={12}>
            <Alert variant={"warning"}>
              {
                "No more items left in the list. Please try removing/changing client and customer filters for more results."
              }
            </Alert>
          </Col>
        </Row>
      ) : null}
      <DisplayBar
        tableTitle={"Call Groups"}
        totalRecords={totalElements == "loading" ? 0 : totalElements}
        refreshIcon={false}
        toggleBioSimilarBool={isBioSimilarToggle}
        handleBioSimilarToggle={handleBioSimilarToggle}
        reset={reset}
        dataTestId={"text-total-call-group-count"}
        isCallGroupListScreen={true}
      />
      <ServerSideReactTable
        columnDefination={columns}
        data={data}
        currentPage={listState.page}
        sorted={listState.sorted}
        filters={listState.filtered}
        totalPages={pages}
        totalRecords={totalElements == "loading" ? 0 : totalElements}
        loading={loading}
        enableMultiRowSelection={false}
        addRowSelection={false}
        defaultPageSize={pageSize}
        attributeName={CALL_GROUP_LIST_ROW_COUNT}
        pageSizeOptions={AgentWorkspaceConstants.DEFAULT_PAGE_SIZE_OPTIONS}
        onUpdateTableData={onUpdateTable}
        onRowClick={(row) => {
          handleRowClick(row);
        }}
        trStyle={getRowStyle}
      />
    </Container>
  );
};

export default withRouter(React.memo(CallGroupList));
