const ColumnTypes = {
    Link: 'Link',
    Text: 'Text',
    RouteableLink: 'RouteableLink',
    DateColumn:'DateColumn',
    DateTimeColumn: 'DateTimeColumn',
    WrapTextOnNoSpace:'WrapTextOnNoSpace',
    Boolean: 'Boolean',
    Custom:'Custom',
    Money:'Money',
  };
  
  const FilterTypes = {
    MultiSelect: 'MultiSelect',
    SingleSelect: 'SingleSelect',
    SearchableInput: 'SearchableInput',
    Date: 'Date',
    DateRangePicker: 'DateRangePicker',
    MultiSelectAll: 'MultiSelectAll',
  };


   const SELECT_ALL = 'ALL';
  
  export { ColumnTypes, FilterTypes, SELECT_ALL };