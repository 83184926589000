import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Redirect } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik } from "formik";
import { RawSubmitForReview } from "views/Raw/SwitchDisposition/SwitchDisposition";
import { isLoggedInUserAWAdmin } from "services/AWUtilityService";
import SaveDispositionPopover from "components/SaveDispositionPopover";
import PushToPharmacyPopover from "components/PushToPharmacyPopover";
import CreateActivity from "components/SwitchDetails/CreateActivity";
import ChangeSwitchTemplate from "components/SwitchDetails/ChangeSwitchTemplate";
import UnlinkDeclinedFax from "components/UnlinkDeclinedFax/UnlinkDeclinedFax";

import {
  submitDisposition,
  resetSubmittedDisposition,
  triggerEventOnRaw,
  resetError,
  resetEventRequest,
} from "../../views/Raw/SwitchDisposition/SwitchDisposition.actions";
import { Alert, FormSelect } from "react-bootstrap";
import { WorkflowConstants } from "lookup/WorkflowConstants";
import { showNotification } from "services/NotificationService";

class SwitchDispositionForm extends Component {
  componentWillUnmount() {
    this.props.resetSubmittedDisposition();
    this.props.resetEventRequest();
  }

  renderTargetOption = (t) => {
    return (
      <span>
        {!t.itemStrength ||
        t.itemStrength === "NONE" ? null : !t.itemUnitOfMeasure ? (
          <strong>({t.itemStrength})</strong>
        ) : (
          <strong>({`${t.itemStrength} ${t.itemUnitOfMeasure}`})</strong>
        )}
        &nbsp;
        {t.instructions ? t.instructions : null}
      </span>
    );
  };

  submitDisposition(values) {
    const validationMessage = this.validateForm(values);
    if (validationMessage != null) {
      alert(validationMessage);
      return;
    }
    const { rawId } = this.props.rawDetails;
    const disposition = {
      disposition: values.disposition,
      selectedTargets: values.targets,
      rejectionReason: values.rejectionReason,
      futureSwitchTreatment: values.futureSwitchTreatment,
      note: {
        note:
          this.props.rawDetails.wfItemStatus ===
          WorkflowConstants.DELIVERED_PRESCRIBER_FAX
            ? `${values.disposition} - over the phone  -- ${values.notes}`
            : values.notes,
      },
      pushToPharmacy: false,
    };
    this.props.submitDisposition(rawId, disposition);
  }

  submitPushToPharmacy(disposition) {
    const { rawId } = this.props.rawDetails;
    this.props.submitDisposition(rawId, disposition);
  }

  setRejectionReason = (rejectionReasonDropdown, setValue) => {
    const { value, name } = rejectionReasonDropdown;
    const {
      futureSwitchHandlers,
      rejectionReasonsToFutureSwitchHandlersMapping,
    } = this.props.rawDetails;

    setValue(name, value);

    if (value) {
      const rejectionReasonCode = value.split("-")[0];
      const futureSwitchHandlerCode =
        rejectionReasonsToFutureSwitchHandlersMapping[rejectionReasonCode];
      let futureSwitchTreatment = "";

      for (let index in futureSwitchHandlers) {
        if (futureSwitchHandlers[index].startsWith(futureSwitchHandlerCode)) {
          futureSwitchTreatment = futureSwitchHandlers[index];
          break;
        }
      }
      setValue("futureSwitchTreatment", futureSwitchTreatment);
    }
  };

  resetDisposition = (dispositionSelect, setValue) => {
    const { value, name } = dispositionSelect;
    setValue(name, value);
    setValue("targets", []);
    setValue("rejectionReason", "");
    setValue("futureSwitchTreatment", "");
    setValue("notes", "");
  };

  validateForm(values) {
    const selectableDispositions = this.props.selectableDispositions;
    const targetOptions = this.props.rawDetails.targetOptions[0];
    let items = [];
    if (targetOptions) {
      const { dynamicSwitchItems } = targetOptions;
      items = dynamicSwitchItems;
    }
    if (values.disposition === "") {
      return "Please select disposition.";
    } else if (values.disposition === selectableDispositions.ACCEPTED) {
      if (items.length > 1 && values.targets.length == 0)
        return "Please check atleast one target option.";
      return null;
    } else if (
      values.disposition === selectableDispositions.SUBMITTED_VIA_EHR
    ) {
      return null; // no information is mandatory at the moment
    } else if (values.disposition === selectableDispositions.DECLINED) {
      if (values.rejectionReason === "")
        return "Please select rejection reason.";
      else if (values.futureSwitchTreatment === "")
        return "Please select future switch treatment.";
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      submittedDisposition,
      submittingDisposition,
      eventRequestCompleted,
      onActionExecuted,
      isSubmissionRequestFromSwim,
      changeDisposition,
      submitDispositionFailed,
    } = nextProps;
    let isSubmissionRequestFromAw = false;

    isSubmissionRequestFromAw = true;

    if (submittedDisposition) {
      showNotification({
        title: "Successfully updated Switch Disposition!",
        message: "The switch disposition has been updated for this switch.",
        position: "tr",
        type: "success",
      });
      nextProps.resetSubmittedDisposition();
    } else if (submitDispositionFailed) {
      showNotification({
        title: "Error!",
        message: "Unable to save switch disposition.",
        position: "tr",
        type: "error",
      });
      changeDisposition(false);
      nextProps.resetError();
    }

    if (
      isSubmissionRequestFromSwim &&
      submitDispositionFailed &&
      prevState.submitDispositionFailed !== submitDispositionFailed
    ) {
      showNotification({
        title: "Error!",
        message: "Unable to save switch disposition.",
        position: "bl",
        type: "error",
      });
      changeDisposition(false);
    }

    return {
      submittedDisposition,
      submittingDisposition,
      eventRequestCompleted,
      isSubmissionRequestFromAw,
      submitDispositionFailed,
      onActionExecuted,
    };
  }

  setCheckboxValue = (checkbox, checkboxValues, setValue) => {
    const { value, checked, name } = checkbox;
    if (checked) {
      checkboxValues.push(value);
    } else {
      checkboxValues.splice(checkboxValues.indexOf(value), 1);
    }
    setValue(name, checkboxValues);
  };

  renderRawOwnerInformation(rawDetails, disableActions) {
    let variant = "warning";
    if (!disableActions) {
      variant = "success";
    }

    if (rawDetails.ownerId !== 0 && rawDetails.ownerId !== null)
      return (
        <Alert variant={variant}>
          Claimed by: <strong>{rawDetails.ownerName}</strong>
        </Alert>
      );
  }

  state = {
    submittingDisposition: false,
    submittedDisposition: false,
    eventRequestCompleted: false,
    isSubmissionRequestFromAw: false,
    isSubmissionRequestFromSwim: false,
    submitDispositionFailed: false,
    changeDisposition: false,
  };

  render() {
    const {
      disposition,
      isRawConfirmed,
      disableActions,
      selectableDispositions,
      rawDetails,
      targetOptions,
      contextIsRaw,
      isSubmissionRequestFromSwim,
      changeDisposition,
    } = this.props;
    const {
      submittingDisposition,
      submittedDisposition,
      eventRequestCompleted,
      isSubmissionRequestFromAw,
    } = this.state;

    if (submittedDisposition || eventRequestCompleted) {
      if (isSubmissionRequestFromSwim) {
        this.props.history.goBack();
        return null;
      } else if (contextIsRaw) {
        return <Redirect to={`/raw/list`} />;
      } else if (isSubmissionRequestFromAw) {
        return null;
      } else {
        this.props.history.goBack();
        return null;
      }
    }
    return (
      <Container fluid>
        {!contextIsRaw &&
          this.renderRawOwnerInformation(rawDetails, disableActions)}
        <Formik
          initialValues={
            disposition && !changeDisposition
              ? {
                  disposition: disposition.disposition,
                  targets: disposition.selectedTargets,
                  notes:
                    disposition.note &&
                    disposition.disposition !== selectableDispositions.DECLINED
                      ? disposition.note.note
                      : "",
                  rejectionReason: disposition.rejectionReason,
                  futureSwitchTreatment: disposition.futureSwitchTreatment,
                }
              : {
                  disposition: "",
                  targets: [],
                  notes: "",
                  rejectionReason: "",
                  futureSwitchTreatment: "",
                }
          }
          onSubmit={(values, { setSubmitting }) => {
            this.submitDisposition(values, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group hidden={isRawConfirmed && !changeDisposition}>
                <Form.Label>Disposition</Form.Label>
                <FormSelect
                  id="switchdisposition-form-disposition"
                  onChange={(e) =>
                    this.resetDisposition(e.target, setFieldValue)
                  }
                  onBlur={handleBlur}
                  value={values.disposition}
                  name="disposition"
                  disabled={disableActions}
                >
                  <option value="">Select Disposition</option>
                  {Object.keys(selectableDispositions).map((k, i) =>
                    rawDetails.isControlled ? (
                      selectableDispositions[k] !==
                      selectableDispositions.ACCEPTED ? (
                        <option key={i} value={selectableDispositions[k]}>
                          {k}
                        </option>
                      ) : null
                    ) : (
                      <option key={i} value={selectableDispositions[k]}>
                        {k}
                      </option>
                    )
                  )}
                </FormSelect>

                <Form.Text className="text-muted">
                  Please specify switch disposition
                </Form.Text>
                {/* {errors.disposition && touched.disposition && errors.disposition} */}
              </Form.Group>
              <Form.Group
                hidden={values.disposition !== selectableDispositions.DECLINED}
              >
                <Form.Label>Rejection Reason</Form.Label>
                <FormSelect
                  id="switchdisposition-form-rejectionReason"
                  onChange={(e) =>
                    this.setRejectionReason(e.target, setFieldValue)
                  }
                  onBlur={handleBlur}
                  value={values.rejectionReason}
                  name="rejectionReason"
                >
                  <option value="">Select Rejection Reason</option>
                  {rawDetails.rejectionReasons.map((r, i) => (
                    <option value={r} key={i}>
                      {r}
                    </option>
                  ))}
                </FormSelect>
                <Form.Text className="text-muted">
                  Please specify a reason for rejection
                </Form.Text>
              </Form.Group>
              <Form.Group
                hidden={values.disposition !== selectableDispositions.DECLINED}
              >
                <Form.Label>Future Switch Treatment</Form.Label>
                <FormSelect
                  id="switchdisposition-form-futureSwitchTreatment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.futureSwitchTreatment}
                  name="futureSwitchTreatment"
                >
                  <option value="">Select Future Switch Handling</option>
                  {rawDetails.futureSwitchHandlers.map((r, i) => (
                    <option value={r} key={i}>
                      {r}
                    </option>
                  ))}
                </FormSelect>
                <Form.Text className="text-muted">
                  Please specify future treatment for this switch
                </Form.Text>
              </Form.Group>
              <Form.Group
                hidden={values.disposition !== selectableDispositions.ACCEPTED}
              >
                <Form.Label>Target Option</Form.Label>
                {targetOptions.map((option, id) => (
                  <div key={id}>
                    {
                      <strong>
                        {option.faxDisplayName || option.medName}
                        &nbsp;({option.medForm})
                      </strong>
                    }
                    {
                      <div>
                        {option.instructions && (
                          <Fragment>
                            <strong>Form Level Instructions: </strong>
                            <span>{option.instructions}</span>
                          </Fragment>
                        )}
                      </div>
                    }
                    {option.dynamicSwitchItems
                      .filter((item) => item.isActive && !item.isDeleted)
                      .map((item, idx) => (
                        <div key={idx}>
                          {
                            <Form.Check
                              id={`switchdisposition-form-targetOption-${
                                idx + 1
                              }`}
                              type="checkbox"
                              value={item.dynamicSwitchItemId}
                              name="targets"
                              style={{ float: "left" }}
                              onChange={(e) =>
                                this.setCheckboxValue(
                                  e.target,
                                  values.targets,
                                  setFieldValue
                                )
                              }
                              checked={
                                values.targets.indexOf(
                                  item.dynamicSwitchItemId
                                ) >= 0 ||
                                (option.dynamicSwitchItems.length == 1 &&
                                  targetOptions.length == 1)
                              }
                              disabled={disableActions}
                            />
                          }
                          {this.renderTargetOption(item)}
                        </div>
                      ))}
                    <br />
                  </div>
                ))}
                <Form.Text className="text-muted">
                  Please specify selected target option. Add prescriber input to
                  notes.
                </Form.Text>
                {errors.targets && touched.targets && errors.targets}
              </Form.Group>
              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  id="switchdisposition-form-notes"
                  as="textarea"
                  rows={3}
                  placeholder="Notes or comments about this switch"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.notes}
                  name="notes"
                  disabled={
                    values.disposition !== selectableDispositions.DECLINED
                      ? disableActions
                      : values.disposition !== selectableDispositions.DECLINED
                  }
                />
                <Form.Text className="text-muted">
                  {`Notes or comments about this switch (optional)`}
                </Form.Text>
                {errors.notes && touched.notes && errors.notes}
              </Form.Group>
              {contextIsRaw && (
                <Row>
                  <Col md={6} className="d-grid gap-2">
                    <Button
                      id="switchdisposition-btn-unclaim"
                      block="true"
                      variant="info"
                      onClick={() =>
                        this.props.triggerEventOnRaw(
                          rawDetails.rawId,
                          "UNCLAIM"
                        )
                      }
                      disabled={disableActions}
                    >
                      Unclaim
                    </Button>
                    <SaveDispositionPopover
                      disallowEditing={submittingDisposition}
                      disableActions={disableActions}
                      values={values}
                      submitDisposition={this.submitDisposition.bind(this)}
                    />
                  </Col>
                  <Col md={6} className="d-grid gap-2">
                    <CreateActivity
                      wfItemId={rawDetails.wfItemId}
                      isMemberActivityCreatable={
                        rawDetails.isMemberActivityCreatable
                      }
                      contextIsRaw={contextIsRaw}
                      disableButton={!isLoggedInUserAWAdmin()}
                      selectedPrescriberUuids={[rawDetails.prescriberUuid] ?? []}
                      selectedPrescriberNames={[rawDetails.prescriberName]?? []}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6} className="d-grid gap-2">
                  <RawSubmitForReview
                    rawId={rawDetails.rawId}
                    triggerEventOnRaw={this.props.triggerEventOnRaw}
                    disallowEditing={disableActions}
                    contextIsRaw={contextIsRaw}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} className="d-grid gap-2">
                  <ChangeSwitchTemplate
                    rirSwitchId={rawDetails.switchId}
                    selectedTemplate={rawDetails.templateIndicator}
                    disableButton={!isLoggedInUserAWAdmin()}
                    contextIsRaw={contextIsRaw}
                  />
                </Col>
              </Row>
              <Row>
                {values.disposition == selectableDispositions.DECLINED ||
                (disposition && disposition.disposition === "DECLINED") ? (
                  <Col md={6} className="d-grid gap-2">
                    <UnlinkDeclinedFax
                      wfItemId={rawDetails.wfItemId}
                      globalSwitchId={this.props.globalSwitchId}
                      history={this.props.history}
                    />
                  </Col>
                ) : null}
                <Col md={6} className="d-grid gap-2">
                  <PushToPharmacyPopover
                    disallowEditing={submittingDisposition}
                    disableActions={
                      rawDetails.wfItemStatus !==
                      WorkflowConstants.SUBMITTED_VIA_EHR
                    }
                    values={values}
                    globalSwitchId={rawDetails.globalSwitchId}
                    submitPushToPharmacy={this.submitPushToPharmacy.bind(this)}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Container>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    submittingDisposition: state.switchDisposition.submittingDisposition,
    submittedDisposition: state.switchDisposition.submittedDisposition,
    eventRequestCompleted: state.switchDisposition.eventRequestCompleted,
    submitDispositionFailed: state.switchDisposition.submitDispositionFailed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitDisposition: bindActionCreators(submitDisposition, dispatch),
    triggerEventOnRaw: bindActionCreators(triggerEventOnRaw, dispatch),
    resetSubmittedDisposition: bindActionCreators(
      resetSubmittedDisposition,
      dispatch
    ),
    resetError: bindActionCreators(resetError, dispatch),
    resetEventRequest: bindActionCreators(resetEventRequest, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwitchDispositionForm));
