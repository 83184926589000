import React from "react";
import { Column } from './Column';
import Checkbox from './Checkbox';
import ColumnFilter from './filters/ColumnFilter';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import { FilterView } from './FilterView';
import './style.css';
import {
  flexRender
} from 'react-table-7';
import "../../assets/css/switch.css"

export const columnsContent = (columnDefination, addRowSelection, enableExpansion, enableAllRowSelect) => {
  const columns = columnDefination.map((column) => ({
    ...column,
    show: column.show === undefined || column.show ? true : column.show,
    canSort: column.canSort === undefined ? true : column.canSort,
    disableSortBy: !column.canSort,
    sortDescFirst: false,
    id: column.accessor,
    cell: (info) => (
      <Column
        type={info.column.columnDef.columnType}
        value={
          column.returnRow
            ? Object.fromEntries(
                info.row
                  .getAllCells()
                  .map((column) => [column.column.id, column.getValue()])
              )
            : info.getValue()
        }
        link={info.column.columnDef.link}
        customComponent={column.customComponent}
        row={info.row}
      />
    ),
  }));
  if (enableExpansion) {
    columns.unshift({
      id: "expand",
      enableSorting: false,
      disableSortBy: true,
      cell: ({ row }) => (
        <div className="px-0">
          {row.getCanExpand() && (
            <button onClick={row.getToggleExpandedHandler()}>
              {row.getIsExpanded() ? "-" : "+"}
            </button>
          )}
        </div>
      ),
    });
  }
  if (addRowSelection) {
    columns.unshift({
      id: "select",
      enableSorting: false,
      disableSortBy: true,
      cell: ({ row }) => (
        <div className="px-1">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
      header: ({ table }) => {
        return (<div>
          {enableAllRowSelect ? <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          /> : null}
        </div>);
      },
    });
  }
  return columns;
};


export const getRowId = (row, relativeIndex, overrideRowId = null, currentPage = null) => {
  if (overrideRowId && row[overrideRowId])
    return row[overrideRowId]
  if (currentPage)
    return [currentPage, relativeIndex].join(".")
  return relativeIndex;
};

export const useReactTableHooks = (hooks) => {
  if (addRowSelection) {
    hooks.visibleColumns.push((columns) => [
      {
        id: "selection",
        Cell: ({ row }) => {
          return (
            <div>
              <Checkbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
        width: 20,
      },
      ...columns,
    ]);
  }
};

export const renderNoDataComponent = (rowLength, columnsLength) => {
  if (rowLength === 0) {
    return (
      <tr>
        <td colSpan={columnsLength}>No data available</td>
      </tr>
    );
  }
  return null;
};

const renderFilterInput = (column) => {
  const columnDef = column.columnDef;
  return (
    <ColumnFilter
      column={columnDef}
      handleFilterChange={(e, value) => column.setFilterValue(value)}
      filterValue={column.getFilterValue()}
    />
  );
};

const toggleButton = (columnDef) => {
  return (
    <>
      <label className="switch">
        <input type="checkbox" checked={columnDef.isDisabled} onChange={columnDef.handleToggle} />
        <span className="slider" data-on={"User"} data-off={"Global"}></span>
      </label>
    </>
  );
}

export const renderTableHeaderContent = (tableHeaderGroups) => {
  return tableHeaderGroups.map((headerGroup) => (
    <React.Fragment key="header">
      <tr>
        {headerGroup.headers.map((header) => (
          <th
            key={header.id}
            colSpan={header.colSpan}
            style={{
              width: null,
              textOverflow: "ellipsis",
            }}
          >
            <div className="table-header-div">
              <div
                className="table-header"
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
                {header.column.getIsSorted() !== false ? (
                  <span>
                    {{
                      asc: <FaSortUp />,
                      desc: <FaSortDown />,
                    }[header.column.getIsSorted()] ?? null}
                  </span>
                ) : null}
              </div>
              {header.column.columnDef.enableColumnFilter ? (
                <FilterView column={header.column.columnDef} filterValues={header.column.getFilterValue()} customFilter={true} />) : null}
              {header.column.columnDef.hasToggleButton ? toggleButton(header.column.columnDef) : null}
            </div>
          </th>
        ))}
      </tr>
      <tr>
        {headerGroup.headers.map((header) => (
          <th key={`${header.id}-filter`}>
            {header.column.columnDef.enableColumnFilter
              ? renderFilterInput(header.column)
              : null}
          </th>
        ))}
      </tr>
    </React.Fragment>
  ));
};

export const renderTableRowsContent = (
  rows,
  handleRowClick,
  trStyle,
  tdStyle
) => {
  return rows.map((row) => {
    return (
      <tr key={row.id} style={trStyle(row)}>
        {row.getVisibleCells().map((cell) => {
          return (
            <td
              key={cell.id}
              onClick={() => handleRowClick(row, cell)}
              style={tdStyle(row, cell)}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    );
  });
};
