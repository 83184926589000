import MultiSelect from "./MultiSelect";
import SingleSelect from "./SingleSelect";
import SearchableInput from "./SearchableInput";
import DateFilter from "./DateFilter";
import MultiSelectFilterWithAll from "./MultiSelectAll";
import DateRangePickerFilter from "./DateRangePickerFilter";
import { FilterTypes } from "../Constants";

const ColumnFilter = ({ column, handleFilterChange, filterValue }) => {
    const filterType=column.filterType;

    switch (filterType) {
        case FilterTypes.MultiSelect:
            return <MultiSelect
                column={column}
                handleFilterChange={handleFilterChange}
                filterValue={filterValue}
            />
        case FilterTypes.SingleSelect:
            return <SingleSelect
                column={column}
                handleFilterChange={handleFilterChange}
                filterValue={filterValue}
            />
        case FilterTypes.SearchableInput:
            return <SearchableInput
                column={column}
                handleFilterChange={handleFilterChange}
                filterValue={filterValue}
            />
        case FilterTypes.Date:
            return <DateFilter
                column={column}
                handleFilterChange={handleFilterChange}
                filterValue={filterValue}
            />
        case FilterTypes.DateRangePicker:
            return <DateRangePickerFilter
                column={column}
                handleFilterChange={handleFilterChange}
                filterValue={filterValue}
            />
        case FilterTypes.MultiSelectAll:
            return <MultiSelectFilterWithAll
                column={column}
                handleFilterChange={handleFilterChange}
                filterValue={filterValue}
            />
        default:
            return null;
    }
};

export default ColumnFilter;