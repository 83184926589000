import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateSwimSwitchList,
  updateIndexOnList,
  resetError,
  updateIsShowBioSimilarToggle,
} from "./SwimSwitchList.actions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Form } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { withRouter } from "react-router-dom";
import ResetListState from "components/ResetListState";
import { isLoggedInUserBioSimilarUser } from "services/AWUtilityService";
import { COLUMN } from "./CreateColumnListForSwimSwitchList";
import CreateActivity from "components/SwitchDetails/CreateActivity";
import Container from "react-bootstrap/Container";
import SwimSwitchListCommon from "./SwimSwitchListCommon";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import colors from "lookup/Colors";
import {
  DEFAULT_SWIM_SWITCH_SORT,
  SWIM_SWITCH_FILTER,
} from "lookup/SwimConstants";
import { isLoggedInUserAWAdmin } from "services/AWUtilityService";

const DEFAULT_PAGE_SIZE = 15;

const COLUMN_LIST = [
  COLUMN.customerNameAbbreviation,
  COLUMN.clientNameAbbreviation,
  COLUMN.switchNumber,
  COLUMN.scheduledDate,
  COLUMN.prescriberName,
  COLUMN.prescriberFax,
  COLUMN.prescriberPhone,
  COLUMN.prescriberNpi,
  COLUMN.pharmacyName,
  COLUMN.pharmacyFax,
  COLUMN.pharmacyPhone,
  COLUMN.pharmacyNpi,
  COLUMN.type,
  COLUMN.activityCount,
  COLUMN.status,
];
class SwimSwitchList extends React.Component {
  state = {
    selectedSwitches: [],
    selectedPrescriberUuids: [],
    selectedPrescriberNames: [],
    isInitialLoadComplete: false,
  };
  resetList = () => {
    let { pageSize, resized, expanded, filtered } = this.props.listState;
    let { isShowBioSimilarToggle } = this.props;
    let bioSimilarToggle = isShowBioSimilarToggle;
    if (isLoggedInUserBioSimilarUser() && !isLoggedInUserAWAdmin()) {
      bioSimilarToggle = true;
      filtered = [
        { id: "priorityAgent", value: true },
        { id: "customerNameAbbreviation", value: "WMT" },
        { id: "clientNameAbbreviation", value: "WMT" },
      ];
      this.props.location.state = null;
      this.props.updateSwimSwitchList(
        pageSize,
        0,
        DEFAULT_SWIM_SWITCH_SORT,
        filtered,
        resized,
        expanded
      );
    } else {
      bioSimilarToggle = false;
      filtered = [{ id: "priorityAgent", value: false }];
      this.props.location.state = null;
      this.props.updateSwimSwitchList(
        pageSize,
        0,
        DEFAULT_SWIM_SWITCH_SORT,
        filtered,
        resized,
        expanded
      );
    }
    this.props.updateIsShowBioSimilarToggle(bioSimilarToggle);
  };

  componentDidMount() {
    const { isShowBioSimilarToggle } = this.props;
    if (isShowBioSimilarToggle === undefined) {
      let bioSimilarToggle = isShowBioSimilarToggle;
      const isBioSimilarUser = isLoggedInUserBioSimilarUser();
      if (isBioSimilarUser && !isLoggedInUserAWAdmin()) {
        bioSimilarToggle = true;
        this.setState({ isInitialLoadComplete: true });
      } else {
        bioSimilarToggle = false;
        this.setState({ isInitialLoadComplete: true });
      }
      this.props.updateIsShowBioSimilarToggle(bioSimilarToggle);
    } else {
      this.setState({ isInitialLoadComplete: true });
    }
  }

  handleBioSimilarToggle = (e) => {
    const { filtered } = this.props.listState;
    let newFiltered = [];
    if (e.target.checked) {
      newFiltered = newFiltered.filter(
        (element) =>
          ![
            "priorityAgent",
            "customerNameAbbreviation",
            "clientNameAbbreviation",
          ].includes(element.id)
      );
      newFiltered.push(
        { id: "priorityAgent", value: true },
        { id: "customerNameAbbreviation", value: "WMT" },
        { id: "clientNameAbbreviation", value: "WMT" }
      );
      if (JSON.stringify(newFiltered) !== JSON.stringify(filtered)) {
        this.props.updateSwimSwitchList(
          this.props.listState.pageSize,
          this.props.listState.page,
          this.props.listState.sorted,
          newFiltered,
          this.props.listState.resized,
          this.props.listState.expanded
        );
      }
    } else {
      newFiltered = newFiltered.filter(
        (element) =>
          ![
            "priorityAgent",
            "customerNameAbbreviation",
            "clientNameAbbreviation",
          ].includes(element.id)
      );
      newFiltered.push({ id: "priorityAgent", value: false });
      if (JSON.stringify(newFiltered) !== JSON.stringify(filtered)) {
        this.props.updateSwimSwitchList(
          this.props.listState.pageSize,
          this.props.listState.page,
          this.props.listState.sorted,
          newFiltered,
          this.props.listState.resized,
          this.props.listState.expanded
        );
      }
    }
    this.props.updateIsShowBioSimilarToggle(e.target.checked);
  };

  toggleSelection = (selected) => {
    selected = Object.keys(selected).map((row, index) => {
      return row;
    });
    const prescriberData = this.props.data.reduce(
      (acc, row) => {
        if (selected.includes(row.wfItemId)) {
          acc.uuids.add(row.prescriberUuid);
          acc.names.add(row.prescriberName);
        }
        return acc;
      },
      { uuids: new Set(), names: new Set() }
    );    
    this.setState({
      selectedSwitches: selected,
      selectedPrescriberUuids:  Array.from(prescriberData.uuids),
      selectedPrescriberNames: Array.from(prescriberData.names),
    });
  };

  render() {
    return (
      <Container className="list-container" fluid>
        <Row>
          <Col md={12}>
            <div
              className="codex-version-display-bar"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>
                  Total Switches: <strong>{this.props.totalElements}</strong>
                </span>
                <span style={{ paddingLeft: "2vh" }}>
                  <CreateActivity
                    wfItemId={
                      this.state.selectedSwitches.length > 0
                        ? this.state.selectedSwitches[0]
                        : null
                    }
                    isMemberActivityCreatable={false}
                    disableButton={
                      this.state.selectedSwitches.length > 0 ? false : true
                    }
                    selectedSwitches={this.state.selectedSwitches}
                    openModalButtonStyle="warning"
                    contextIsAW={true}
                    selectedPrescriberUuids={this.state.selectedPrescriberUuids}
                    selectedPrescriberNames={this.state.selectedPrescriberNames}
                  />
                </span>
              </div>
              <strong style={{ textAlign: "center" }}>Swim Switch List</strong>
              <div style={{ display: "flex", alignItems: "center" }}>
                {isLoggedInUserBioSimilarUser() && (
                  <span
                    className="tiny-bottom-spacer"
                    style={{ marginRight: "15px" }}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          Toggling this ON will show Bio Similar Switches
                        </Tooltip>
                      }
                    >
                      <Form.Check
                        type="switch"
                        className="aw-bio-similar-callgroups-toggle"
                        id="toggleBioSimilarRef"
                        label={`Bio Similar Switches`}
                        onChange={(e) => this.handleBioSimilarToggle(e)}
                        checked={this.props.isShowBioSimilarToggle}
                      />
                    </OverlayTrigger>
                  </span>
                )}
                <ResetListState onReset={this.resetList} />
              </div>
            </div>
          </Col>
        </Row>
        <SwimSwitchListCommon
          updateInterventionList={this.props.updateSwimSwitchList}
          updateIndexOnList={this.props.updateIndexOnList}
          resetError={this.props.resetError}
          data={this.props.data}
          pages={this.props.pages}
          totalElements={this.props.totalElements}
          loading={this.props.loading}
          listState={
            this.props.location.state
              ? this.props.location.state
              : this.props.listState
          }
          isError={this.props.isError}
          columnList={COLUMN_LIST}
          isSortFilterEnabledForCust={true}
          toggleSelection={this.toggleSelection}
          isInitialLoadComplete={this.state.isInitialLoadComplete}
          isShowBioSimilarToggle={this.props.isShowBioSimilarToggle}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.swimSwitchList.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: DEFAULT_SWIM_SWITCH_SORT,
      filtered:
        isLoggedInUserBioSimilarUser() && !isLoggedInUserAWAdmin()
          ? [{ id: "priorityAgent", value: true }]
          : [
              {
                id: "priorityAgent",
                value: false,
              },
            ],
      resized: [],
      expanded: {},
    };
  }
  return {
    data:
      state.swimSwitchList.data !== undefined ? state.swimSwitchList.data : [],
    pages: state.swimSwitchList.pages ? state.swimSwitchList.pages : 0,
    totalElements:
      state.swimSwitchList.totalElements !== undefined
        ? state.swimSwitchList.totalElements
        : "Loading ...",
    loading: state.swimSwitchList.loading,
    listState: listState,
    isError: state.swimSwitchList.isError,
    isShowBioSimilarToggle: state.swimSwitchList.isShowBioSimilar ?? undefined,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSwimSwitchList: bindActionCreators(updateSwimSwitchList, dispatch),
    updateIndexOnList: bindActionCreators(updateIndexOnList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
    updateIsShowBioSimilarToggle: bindActionCreators(
      updateIsShowBioSimilarToggle,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SwimSwitchList));
