import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResetListState from '../../../components/ResetListState';
import { BatchConstants } from 'lookup/BatchConstants';
import { withRouter } from 'react-router-dom';
import { MdClose } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { updateBatchList, resetError, updateSwitchList } from './BatchList.actions';
import { formatDateWithTime } from 'services/MomentUtils';
import { twoDecimalPlaceRounder } from 'services/UtilityService';
import { WrapTextAndCenterAlign } from 'components/WrapText';
import './BatchList.css';
import { showNotification } from 'services/NotificationService';
import ServerSideReactTable from 'commons/table/ServerSideReactTable';
import { FilterTypes, ColumnTypes } from 'commons/table/Constants';
import ClientSideReactTable from 'commons/table/ClientSideReactTable';
import { WrapTextAndJustifyCenter } from 'components/WrapText';
import EditBatch from './EditBatch';
import ReactBadge from "react-bootstrap/Badge";
const Badge = (props) => (
  <div>
    <ReactBadge {...props}>{props.children}</ReactBadge>
  </div>
);

const DEFAULT_PAGE_SIZE = 15;
class BatchList extends React.Component {
  constructor(props) {
    super(props);

    this.handler = this.handler.bind(this);
  }

  state = {
    loading: true,
    selectedBatchId: -1,
    data: this.props.data,
    switchData: this.props.switchData,
    isError: false,
    switchListState: this.props.switchListState,
    pages: this.props.pages,
    switchPages: this.props.switchPages,
    switchListTotalElements: this.props.switchListTotalElements,
    totalElements: this.props.totalElements,
    listState: this.props.listState,
    switchLoading: this.props.switchLoading,
    displayAllColumn: true,
  }

  updateData = (state) => {
    if (state.sorted !== BatchConstants.DEFAULT_BATCH_LIST_SORTING)
      state.sorted.forEach((element, i) => {
        element.isDefault = false
      });
    const filteredForStatus = state.filtered.filter(element => !(element.id === 'status' && element.value.includes("ALL_FILTERS")))
    if (filteredForStatus.length !== state.filtered.length) {
      state.filtered = filteredForStatus
    }
    this.props.updateBatchList(state.pageSize, state.page, state.sorted, state.filtered, state.resized, state.expanded);

  }

  onUpdateTable = (filterValues, sortBy, pageSize, pageNo) => {
    this.state.listState.pageSize = pageSize;
    this.state.listState.page = pageNo;
    this.state.listState.sorted = sortBy ? sortBy : [];
    this.state.listState.filtered = filterValues;
    this.updateData(this.state.listState);
  }

  updateSwitchData = (state, preventUpdate, batchId) => {
    if (!preventUpdate && batchId !== -1) {
      this.props.updateSwitchList(batchId, state.pageSize, state.page, state.sorted, state.filtered, state.resized, state.expanded);
    }
    else {
      this.setState({ switchListState: state });
    }
  }

  onUpdateSwitchTable = (filterValues, sortBy, pageSize, pageNo) => {
    this.state.switchListState.pageSize = pageSize;
    this.state.switchListState.page = pageNo;
    this.state.switchListState.sorted = sortBy ? sortBy : [];
    this.state.switchListState.filtered = filterValues;
    this.updateSwitchData(this.state.switchListState, false, this.state.selectedBatchId);
  }

  handler() {
    this.updateData(this.state.listState, false);
  }

  componentDidMount() {
    this.updateData(this.state.listState);
  }

  componentDidUpdate(prevProps) {
    const { data, pages, totalElements, listState, switchData, loading, switchListState, switchPages, switchListTotalElements, switchLoading, isError, resetError } = this.props;

    if (prevProps.data !== data ||
      prevProps.pages !== pages ||
      prevProps.totalElements !== totalElements ||
      prevProps.listState !== listState ||
      prevProps.switchData !== switchData ||
      prevProps.loading !== loading ||
      prevProps.switchListState !== switchListState ||
      prevProps.switchPages !== switchPages ||
      prevProps.switchListTotalElements !== switchListTotalElements ||
      prevProps.switchLoading !== switchLoading) {
      this.setState({ data, pages, totalElements, listState, switchData, loading, switchListState, switchPages, switchLoading, switchListTotalElements });
    }

    if (isError === true && prevProps.isError !== isError) {
      showNotification({
        title: 'Error Loading Data!',
        message: 'Unable to load data.',
        position: 'bl',
        type: 'error'
      });
      resetError();
    }
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value, error: "" });
  }

  widthOfTotalSwitch = this.state.displayAllColumn ? 95 : 100
  widthOfSwitchDescription = this.state.displayAllColumn ? 110 : 115

  renderActions(rowData) {
    return (
      <span className="action-button">
        <EditBatch
          batch={rowData}
          showButton={true}
          handler={this.handler}
        ></EditBatch>
      </span>
    );
  }

  renderRowDescription(rowData) {
    return (
      <span style={{ display: "flex" }}>
        <span style={{ flex: "9" }}>
          {rowData.description}
        </span>

        <span className="edited-tag">
          {rowData.descriptionEdited ?
            <Badge
              variant="primary"
              box="true"
              className="badge-custom-edited"
            >
              Edited
            </Badge>
          : ""}
        </span>
      </span>
    );
  }

  renderDateCell = (date) => {
    return (
      <span>
        {formatDateWithTime(date)}
      </span>
    );
  };

  renderSavingsCell = (savings) => `$ ${twoDecimalPlaceRounder(savings)}`;

  render() {
    const { data, pages, switchData, listState, totalElements, loading, selectedBatchId, switchListState, switchLoading, switchPages, switchListTotalElements } = this.state;

    switchData.forEach(element => {
      element.lastCycleId = element.lastCycleId || '-';
    });


    const columns = [
      {
        header: "Description",
        accessor: "description",
        accessorKey: "description",
        enableSorting: false,
        width: this.widthOfSwitchDescription,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.SearchableInput,
        customComponent: (value, row) => {
          return this.renderRowDescription(row.original);
        },
      },
      {
        header: "Batch Status",
        accessor: "status",
        accessorKey: "status",
        width: 115,
        show: this.state.displayAllColumn,
        enableSorting: true,
        enableColumnFilter: true,
        columnType: ColumnTypes.Custom,
        filterType: FilterTypes.SingleSelect,
        filterOptions: BatchConstants.statuses,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Scheduled/Execution Date",
        accessor: "dateScheduled",
        accessorKey: "dateScheduled",
        enableSorting: true,
        width: 210,
        show: this.state.displayAllColumn,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return this.renderDateCell(value)
        },
      },
      {
        header: "Total Switches",
        accessor: "numberOfSwitch",
        accessorKey: "numberOfSwitch",
        enableSorting: false,
        width: this.widthOfTotalSwitch,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Alternate Activity Creation",
        accessor: "isEvenSwitchActivityCreationEnabled",
        accessorKey: "isEvenSwitchActivityCreationEnabled",
        enableSorting: false,
        width: this.widthOfTotalSwitch,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value ? "Enabled" : "Disabled"}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Expired Switches",
        accessor: "numberOfExpiredSwitch",
        accessorKey: "numberOfExpiredSwitch",
        enableSorting: false,
        enableColumnFilter: false,
        width: 130,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Succesfully Delivered",
        accessor: "numberOfSuccessfullyDeliveredSwitch",
        accessorKey: "numberOfSuccessfullyDeliveredSwitch",
        enableSorting: false,
        enableColumnFilter: false,
        width: 150,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Failed Delivery",
        accessor: "numberOfFailedSwitch",
        accessorKey: "numberOfFailedSwitch",
        enableSorting: false,
        enableColumnFilter: false,
        width: 120,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Error",
        accessor: "numberOfErrorInDelivery",
        accessorKey: "numberOfErrorInDelivery",
        enableSorting: false,
        enableColumnFilter: false,
        width: 50,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Responses Recieved",
        accessor: "numberOfSwitchesWithResponse",
        accessorKey: "numberOfSwitchesWithResponse",
        enableSorting: false,
        enableColumnFilter: false,
        width: 140,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Declined State",
        accessor: "numberOfDeclined",
        accessorKey: "numberOfDeclined",
        enableSorting: false,
        enableColumnFilter: false,
        width: 140,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Closed State",
        accessor: "numberOfClosed",
        accessorKey: "numberOfClosed",
        enableSorting: false,
        enableColumnFilter: false,
        width: 100,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Fill Pending State",
        accessor: "numberOfFillPending",
        accessorKey: "numberOfFillPending",
        enableSorting: false,
        enableColumnFilter: false,
        width: 120,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Others",
        accessor: "others",
        accessorKey: "others",
        enableSorting: false,
        enableColumnFilter: false,
        width: 55,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Projected Savings",
        accessor: "projectedSavings",
        accessorKey: "projectedSavings",
        enableSorting: false,
        enableColumnFilter: false,
        width: 120,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Completed Date/Time",
        accessor: "completionDate",
        accessorKey: "completionDate",
        enableSorting: false,
        width: 210,
        show: this.state.displayAllColumn,
        enableColumnFilter: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return row.original.status === "CLOSED" ? this.renderDateCell(value) : <WrapTextAndJustifyCenter>N/A</WrapTextAndJustifyCenter>
        },
      },
      {
        header: "Action",
        accessor: "action",
        accessorKey: "action",
        enableSorting: false,
        enableFiltering: false,
        show: this.state.displayAllColumn,
        columnType: ColumnTypes.Custom,
        customComponent: (value, row) => {
          return this.renderActions(row.original);
        },
      },
    ];

    const switchColumns = [
      {
        header: "Switch Id",
        accessor: "switchId",
        accessorKey: "switchId",
        enableSorting: false,
        enableColumnFilter: false,
        width: 95,
        show: false,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Cust",
        accessor: "customerNameAbbreviation",
        accessorKey: "customerNameAbbreviation",
        enableSorting: false,
        enableColumnFilter: false,
        width: 60,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Client",
        accessor: "clientNameAbbreviation",
        accessorKey: "clientNameAbbreviation",
        enableSorting: false,
        enableColumnFilter: false,
        width: 60,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Switch Id",
        accessor: "switchNumber",
        accessorKey: "switchNumber",
        enableSorting: true,
        enableColumnFilter: false,
        width: 60,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "# of Messages Sent",
        accessor: "numberOfTimeFaxSent",
        accessorKey: "numberOfTimeFaxSent",
        enableSorting: false,
        enableColumnFilter: false,
        width: 150,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "# of Messages Delivered",
        accessor: "numberOfDeliveredFax",
        accessorKey: "numberOfDeliveredFax",
        enableSorting: false,
        enableColumnFilter: false,
        width: 160,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Current Status",
        accessor: "currentStatus",
        accessorKey: "currentStatus",
        enableSorting: true,
        enableColumnFilter: false,
        width: 150,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Scheduled Date",
        accessor: "scheduledDispatchTime",
        accessorKey: "scheduledDispatchTime",
        enableSorting: true,
        enableColumnFilter: false,
        width: 150,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value ? this.renderDateCell(value) : 'N/A'}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Fax Status",
        accessor: "faxBucketStatus",
        accessorKey: "faxBucketStatus",
        enableSorting: true,
        enableColumnFilter: false,
        width: 150,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return <WrapTextAndCenterAlign>{value ? value : "N/A"}</WrapTextAndCenterAlign>
        },
      },
      {
        header: "Projected Savings",
        accessor: "projectedSavings",
        accessorKey: "projectedSavings",
        enableSorting: false,
        enableColumnFilter: false,
        width: 140,
        columnType: ColumnTypes.Custom,
        customComponent: (value) => {
          return this.renderSavingsCell(value)
        },
      },
    ];

    return (
      <Container className="list-container" fluid>
        <Row >
          <Col md={12}>
            <div className='codex-version-display-bar'>
              <span style={{ float: 'left' }} >   Total Batches: <strong>{totalElements}</strong></span>
              <strong style={{ float: 'center' }}>Batch List</strong>
              {
                selectedBatchId === -1 ?
                  <ResetListState
                    onReset={() => this.updateData({
                      ...listState, sorted: BatchConstants.DEFAULT_BATCH_LIST_SORTING
                      , filtered: BatchConstants.DEFAULT_BATCH_LIST_FILTERS
                    })}
                    resetterDisabled={listState.sorted === BatchConstants.DEFAULT_BATCH_LIST_SORTING &&
                      listState.filtered === BatchConstants.DEFAULT_BATCH_LIST_FILTERS}
                  /> :
                  null
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={selectedBatchId === -1 ? 12 : 4}>
            <ServerSideReactTable
              columnDefination={columns}
              data={data}
              currentPage={listState.page}
              sorted={listState.sorted}
              filters={listState.filtered}
              totalPages={pages}
              totalRecords={totalElements == "loading" ? 0 : totalElements}
              loading={loading}
              enableMultiRowSelection={false}
              addRowSelection={false}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              pageSizeOptions={[5, 10, 15, 20, 30, 50]}
              onUpdateTableData={this.onUpdateTable}
              trStyle={(rowInfo) => {
                return ({
                  background: (rowInfo && rowInfo.original.wfBatchId === selectedBatchId) ? "#42a5f5" : "",
                  color: (rowInfo && rowInfo.original.wfBatchId === selectedBatchId) ? "#ffffff" : ""
                }
                )
              }
              }
              onRowClick={(rowInfo) => {
                if (rowInfo !== undefined && rowInfo.original.wfBatchId !== undefined && rowInfo.column.id != 'action') {
                  if (rowInfo.original.wfBatchId !== -1) {
                    //this.props.updateSwitchList(rowInfo.original.wfBatchId);
                    switchListState.page = 0
                    this.updateSwitchData(switchListState, false, rowInfo.original.wfBatchId);
                  }
                  this.setState({ selectedBatchId: rowInfo.original.wfBatchId, displayAllColumn: false });
                }
              }}
            />
          </Col>
          {
            selectedBatchId !== -1 ?
              <Col md={8} className="tiny-top-spacer">
                <Row >
                  <Col md={12}>
                    <Button className={"pull-right"} size="sm" variant="custom-hollow" onClick={() => this.setState({ selectedBatchId: -1, displayAllColumn: true })} > <MdClose /> </Button>
                    <h4 className="pull-left">
                      Switches for Batch
                    </h4>
                  </Col>
                </Row>
                <Row className="tiny-top-spacer">
                  <Col md={12}>
                    <ServerSideReactTable
                      currentPage={switchListState.page}
                      sorted={switchListState.sorted}
                      filters={switchListState.filtered}
                      totalPages={switchPages}
                      totalRecords={switchListTotalElements == "loading" ? 0 : switchListTotalElements}
                      enableMultiRowSelection={false}
                      addRowSelection={false}
                      columnDefination={switchColumns}
                      data={switchData}
                      loading={switchLoading}
                      defaultPageSize={switchListState.pageSize ? switchListState.pageSize : DEFAULT_PAGE_SIZE}
                      pageSizeOptions={[5, 10, 15, 20, 30, 50]}
                      onUpdateTableData={this.onUpdateSwitchTable}
                      onRowClick={(rowInfo) => {
                        if (rowInfo !== undefined) {
                          this.props.history.push(`/switch/${rowInfo.original.wfItemId}`);
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              :
              ""
          }
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let listState = state.batchList.listState;
  if (!listState) {
    listState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: BatchConstants.DEFAULT_BATCH_LIST_SORTING,
      filtered: BatchConstants.DEFAULT_BATCH_LIST_FILTERS,
      resized: [],
      expanded: {}
    }
  }
  let switchListState = state.batchList.switchListState;
  if (!switchListState) {
    switchListState = {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      sorted: [],
      filtered: [],
      resized: [],
      expanded: {}
    }
  }
  return {
    data: state.batchList.data !== undefined ? state.batchList.data : [],
    pages: state.batchList.pages !== undefined ? state.batchList.pages : 0,
    switchPages: state.batchList.switchPages !== undefined  ? state.batchList.switchPages : 0,
    switchListTotalElements: state.batchList.switchListTotalElements !== undefined  ? state.batchList.switchListTotalElements : "Loading ...",
    switchData: state.batchList.switchData !== undefined ? state.batchList.switchData : [],
    totalElements: state.batchList.totalElements !== undefined ? state.batchList.totalElements : "Loading ...",
    loading: state.batchList.loading,
    listState: listState,
    switchListState: switchListState,
    switchLoading: state.batchList.switchLoading,
    isError: state.batchList.isError,
    selectedBatchId: state.batchList.selectedBatchId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBatchList: bindActionCreators(updateBatchList, dispatch),
    resetError: bindActionCreators(resetError, dispatch),
    updateSwitchList: bindActionCreators(updateSwitchList, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BatchList));