import { RSAA } from 'redux-api-middleware';
import config from 'config';
import { AUTH_TOKEN_HEADER_KEY, getAuthorizationToken } from '../../../services/AuthService';
import { dateFormatter } from 'services/UtilityService';
import { isCollectionNullOrEmpty } from 'services/UtilityService';
import { SELECT_ALL } from 'commons/table/Constants';

export const updateCallGroupList = (pageSize, page, sorted, filtered, resized, expanded) => {
  const filteredDeepCopy = filterModification(filtered);

  return {
    [RSAA]: {
      endpoint: `${config.apiRoot}/agent-workspace/call-group/list/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json', [AUTH_TOKEN_HEADER_KEY]: getAuthorizationToken() },
      body: JSON.stringify({ pageSize, page, sorted, filtered: filteredDeepCopy }),
      types: [
        'UPDATE_CALL_GROUP_LIST_REQUEST',
        'UPDATE_CALL_GROUP_LIST_SUCCESS',
        'UPDATE_CALL_GROUP_LIST_FAILURE'
      ]
    }
  };
};


export const updateCallGroupIndexOnList = (index) => {
  return {
    type: "UPDATE_CALL_GROUP_INDEX_ON_LIST",
    indexOnList: index
  }

};

export const updateCallGroupListState = (state) => {
  return {
    type: "UPDATE_CALL_GROUP_LIST_STATE",
    payload: { listState: state }
  }
};

export const resetError = () => {
  return {
    type: "RESET_ERROR_MESSAGE_CALL_GROUP_LIST"
  }
};

const filterModification = (filtered) => {
  let filteredDeepCopy = _.cloneDeep(filtered);
  filteredDeepCopy.forEach(function (part, index, filteredArray) {
    if (filteredArray[index].id === 'scheduledDate' && filteredArray[index].value != null) {
      filteredArray[index].value[0] = dateFormatter(filteredArray[index].value[0])
      filteredArray[index].value[1] = dateFormatter(filteredArray[index].value[1])
    }
    filteredDeepCopy = filteredDeepCopy.filter(f => !(f.value instanceof Array) || (f.value instanceof Array && !isCollectionNullOrEmpty(f.value)));
  });

  filteredDeepCopy = filteredDeepCopy.map(filter => {
    if (filter.id === 'clientNameAbbreviation') {
      return {
        ...filter,
        value: Array.isArray(filter.value) ? filter.value.filter(value => value !== SELECT_ALL) : filter.value
      };
    }
    return filter;
  });

  return filteredDeepCopy;
}

export const updateCallGroupClaimer = (data, callGroupId, claimer) => {
  let dataDeepCopy = _.cloneDeep(data);
  dataDeepCopy = dataDeepCopy.map((row) => {
    if (row.callGroupId === callGroupId) {
      return { ...row, claimerName: claimer };
    }
    return row;
  });
  return {
    type: "UPDATE_CALL_GROUP_CLAIMER",
    payload: { data: dataDeepCopy }
  }
};

export const updateIsShowBioSimilarToggle = (value) => {
  return {
    type: "UPDATE_BIO_SIMILAR_TOGGLE",
    payload: value
  }
};
