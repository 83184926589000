import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  linkUnprocessedFaxToSwitch,
  fetchUnprocessedFaxDetails,
  discardFax,
  resetLinkRequestStatus,
  setPriorityOfUnprocessedFax,
  saveNote,
  resetNoteSuccess,
  updateNote,
  resetUpdateNoteSuccess,
  deleteNote,
  resetDeleteNoteSuccess,
  fetchNoteForUnlinkedFax,
  resetDiscardedFax,
} from "./UnprocessedFaxDetails.actions";
import { withRouter } from "react-router-dom";
import { Alert } from "react-bootstrap";

import SecurePdfViewer from "components/SecurePdfViewer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import MemberSwitchList from "components/UnprocessedFaxList/MemberSwitchList";
import DiscardFax from "components/UnprocessedFaxDetails/DiscardFax";
import { UnprocessedFaxConstants } from "lookup/UnprocessedFaxConstants";
import { isStringEmptyOrNull } from "services/UtilityService";
import { showNotification } from "services/NotificationService";
import { getErrorMessage } from "services/UtilityService";

import CONTACTMANAGEMENT_CONSTANTS from "lookup/ContactManagementConstants";

class UnprocessedFaxDetails extends Component {
  constructor(props) {
    super(props);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.onChangeLinkSwitches = this.onChangeLinkSwitches.bind(this);
    this.handleSaveOrUpdateNote = this.handleSaveOrUpdateNote.bind(this);
    this.handleDeleteNote = this.handleDeleteNote.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
  }
  state = {
    faxInfoId: this.props.match.params.faxInfoId,
    unprocessedFaxDetailsStatus: this.props.unprocessedFaxDetailsStatus,
    unprocessedFaxDetails: undefined,
    responseFax: undefined,
    linkRequestStatus: null,
    errorMessage: null,
    saveNoteSuccess: null,
    saveNoteError: null,
    updateNoteSuccess: null,
    updateNoteError: null,
    discardRequestCompleted: false,
    faxPriority: this.props.unprocessedFaxDetails
      ? this.props.unprocessedFaxDetails.faxPriority
      : "",
    disableLinking: true,
    updatedNote: undefined,
    savedNote: undefined,
    noteObject: undefined,
    note: "",
  };

  componentDidMount() {
    this.props.fetchNoteForUnlinkedFax(this.state.faxInfoId);
    this.props.fetchUnprocessedFaxDetails(this.state.faxInfoId);
  }

  componentDidUpdate(prevProps) {
    const {
      unprocessedFaxDetailsStatus,
      unprocessedFaxDetails,
      linkRequestStatus,
      errorMessage,
      discardRequestCompleted,
      saveNoteSuccess,
      saveNoteError,
      updateNoteSuccess,
      updateNoteError,
      updatedNote,
      savedNote,
      deleteNoteSuccess,
      deleteNoteFailure,
      noteObject,
    } = this.props;

    if (prevProps.unprocessedFaxDetailsStatus !== unprocessedFaxDetailsStatus) {
      this.setState({ unprocessedFaxDetailsStatus });
    }

    if (prevProps.unprocessedFaxDetails !== unprocessedFaxDetails) {
      this.setState({
        unprocessedFaxDetails: unprocessedFaxDetails,
        faxId: unprocessedFaxDetails.faxId,
        faxPriority: unprocessedFaxDetails.faxPriority,
      });
    }

    if (prevProps.linkRequestStatus !== linkRequestStatus) {
      if (linkRequestStatus === "success" || discardRequestCompleted) {
        showNotification({
          title: `Fax Link Pages Queue!`,
          message: `Fax link/override page(s) request have been queued successfully`,
          position: "tr",
          type: "success",
        });
        const samePagePath = "/fax-details";
        const isSamePage = window.location.pathname.includes(samePagePath);
        if (isSamePage) {
          this.props.history.goBack();
        }
        this.props.resetLinkRequestStatus();
        return null;
      }
      if (linkRequestStatus === "failure") {
        showNotification({
          title: `Error Linking Fax Page(s)!`,
          message:
            getErrorMessage(errorMessage) ||
            getErrorMessage("SOMETHING_WENT_WRONG"),
          position: "tr",
          type: "error",
        });
        this.props.resetLinkRequestStatus();
        return null;
      }
      this.setState({ linkRequestStatus });
    }

    if (prevProps.errorMessage !== errorMessage) {
      this.setState({ errorMessage });
    }

    if (prevProps.discardRequestCompleted !== discardRequestCompleted) {
      if (discardRequestCompleted === true) {
        showNotification({
          title: `Fax Discarded!`,
          message: `Fax has been discarded successfully`,
          position: "tr",
          type: "success",
        });
        this.props.resetDiscardedFax();
        setTimeout(() => {
          this.props.history.push("/unprocessed-fax/list");
        }, [2000]);
      }
      this.setState({ discardRequestCompleted });
    }

    if (prevProps.saveNoteSuccess !== saveNoteSuccess) {
      this.setState({ saveNoteSuccess });
      if (saveNoteSuccess && !saveNoteError) {
        showNotification({
          title: `Note Saved!`,
          message: `Note has been saved successfully`,
          position: "tr",
          type: "success",
        });
        this.props.resetNoteSuccess();
      }
    }

    if (prevProps.deleteNoteSuccess !== deleteNoteSuccess) {
      if (deleteNoteSuccess && !deleteNoteFailure) {
        showNotification({
          title: `Note Deleted!`,
          message: `Note has been deleted successfully`,
          position: "tr",
          type: "success",
        });
        this.setState({ note: "", noteObject: {} });
        this.props.resetDeleteNoteSuccess();
      }
    }

    if (prevProps.deleteNoteFailure !== deleteNoteFailure) {
      if (deleteNoteFailure && !deleteNoteSuccess) {
        showNotification({
          title: `Error Deleting Note!`,
          message: `Failed to delete note`,
          position: "tr",
          type: "error",
        });
      }
    }

    if (prevProps.saveNoteError !== saveNoteError) {
      this.setState({ saveNoteError });
      if (!saveNoteSuccess && saveNoteError) {
        showNotification({
          title: `Error Saving Note!`,
          message: `Failed to save note`,
          position: "tr",
          type: "error",
        });
        this.props.resetNoteSuccess();
      }
    }

    if (prevProps.updateNoteSuccess !== updateNoteSuccess) {
      this.setState({ updateNoteSuccess });
      if (updateNoteSuccess && !updateNoteError) {
        showNotification({
          title: `Note Updated!`,
          message: `Note has been updated successfully`,
          position: "tr",
          type: "success",
        });
        this.props.resetUpdateNoteSuccess();
      }
    }

    if (prevProps.updateNoteError !== updateNoteError) {
      this.setState({ updateNoteError });
      if (!updateNoteSuccess && updateNoteError) {
        showNotification({
          title: `Error Updating Note!`,
          message: `Failed to update note`,
          position: "tr",
          type: "error",
        });
        this.props.resetUpdateNoteSuccess();
      }
    }

    if (prevProps.noteObject !== noteObject) {
      this.setState({ noteObject: noteObject, note: noteObject?.note ?? "" });
    }

    if (prevProps.savedNote !== savedNote) {
      this.setState({ noteObject: savedNote, note: savedNote.note ?? "" });
    }

    if (prevProps.updatedNote !== updatedNote) {
      this.setState({ noteObject: updatedNote, note: updatedNote.note ?? "" });
    }
  }

  handleSaveOrUpdateNote() {
    const { noteObject, note, faxInfoId } = this.state;
    if (noteObject !== undefined && noteObject?.uflNoteUuid?.length > 0) {
      let newNoteObject = { ...noteObject };
      newNoteObject.note = note;
      this.props.updateNote(faxInfoId, newNoteObject);
    } else {
      let newNoteObject = {};
      newNoteObject.note = note;
      this.props.saveNote(faxInfoId, newNoteObject);
    }
  }
  handleDeleteNote() {
    const { noteObject } = this.state;
    this.props.deleteNote(noteObject.uflNoteUuid);
  }
  handleNoteChange(e) {
    const note = e.target.value;
    this.setState({ note: note });
  }
  handlePriorityChange(e) {
    const { faxInfoId } = this.state;
    const priority = e.target.value;
    this.setState({ faxPriority: priority });
    this.props.setPriorityOfUnprocessedFax(faxInfoId, priority);
  }

  linkUnprocessedFaxToSwitch(values, setSubmitting) {
    setSubmitting(true);
    const { faxId, linkPages } = values;
    this.props.linkUnprocessedFaxToSwitch(faxId, linkPages);
  }
  onChangeLinkSwitches(globalSwitchIds, values, setValues) {
    // update dynamic form
    const linkPages = [];
    globalSwitchIds.forEach((globalSwitchId) => {
      const switchInfo = values.linkPages.find(
        (x) => x.selectedGlobalSwitchId == globalSwitchId
      );
      if (switchInfo) {
        linkPages.push(switchInfo);
      } else {
        linkPages.push({
          isUpsideDown: false,
          overrideAssociatedSwitch: false,
          selectedGlobalSwitchId: globalSwitchId,
          pageIndex: null,
          additionalPages: null,
        });
      }
    });
    setValues({ ...values, linkPages });
    if (globalSwitchIds.length > 0) {
      this.setState({
        disableLinking: false,
      });
    } else {
      this.setState({
        disableLinking: true,
      });
    }
  }
  render() {
    const {
      faxInfoId,
      unprocessedFaxDetails,
      unprocessedFaxDetailsStatus,
      note,
      noteObject,
    } = this.state;
    if (unprocessedFaxDetailsStatus === "failure")
      return "Failed to fetch Fax details";
    else if (
      unprocessedFaxDetailsStatus === "requested" ||
      !unprocessedFaxDetails
    ) {
      return "Fetching Fax Details ... ";
    }
    const validationSchema = Yup.object().shape({
      linkPages: Yup.array().of(
        Yup.object().shape({
          isUpsideDown: Yup.boolean(),
          overrideAssociatedSwitch: Yup.boolean(),
          selectedGlobalSwitchId: Yup.string()
            .typeError("Enter a valid switch Id")
            .required("Global Switch Id is required"),
          pageIndex: Yup.number()
            .required("Change Order is required")
            .min(1, "Page Index must be greater than or equal to 1")
            .max(
              unprocessedFaxDetails?.noOfPages,
              `Page Index must be less than or equal to ${unprocessedFaxDetails?.noOfPages}`
            ),
          additionalPages: Yup.string()
            .typeError("Enter valid comma-separated page numbers")
            .nullable()
            .notRequired(),
        })
      ),
    });

    return (
      <Container fluid>
        <Row>
          <Col md={5}>
            <Breadcrumb>
              <Breadcrumb.Item href={`/unprocessed-fax/list`}>
                Unprocessed Fax List
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Fax {faxInfoId}</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="tiny-top-spacer">
              <Col
                id="dispositionForContainer"
                md={12}
                style={{ fontSize: "1.5em" }}
              >
                <span className="text-muted">Link Fax Page To Switch </span>
              </Col>
            </Row>
            <Row className="tiny-top-spacer">
              <Form.Group>
                <Form.Label>Fax Priority</Form.Label>
                {this.props.faxPriorityError ? (
                  <Alert variant={"danger"} className="owner-alert-box">
                    Fax priority could not be updated.
                  </Alert>
                ) : null}
                <Form.Select
                  value={this.state.faxPriority}
                  onChange={this.handlePriorityChange}
                >
                  <option value="">None</option>
                  {Object.entries(
                    UnprocessedFaxConstants.UNPROCESSED_FAX_PRIORITY
                  ).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            {isStringEmptyOrNull(
              unprocessedFaxDetails?.associatedSwitch
            ) ? null : (
              <Row className="tiny-top-spacer">
                <Col md={12}>
                  <span className="text-muted">
                    Currently Associated With:{" "}
                    {unprocessedFaxDetails?.associatedSwitch}
                  </span>
                </Col>
              </Row>
            )}
            <hr />
            <Formik
              enableReinitialize
              initialValues={{
                faxId: faxInfoId,
                linkPages: [],
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.linkUnprocessedFaxToSwitch(values, setSubmitting);
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setValues,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <FieldArray
                    name="linkPages"
                    render={(arrayHelpers) => (
                      <>
                        {values.linkPages.map((linkPage, i) => (
                          <Row key={i}>
                            <Col md={5}>
                              <Form.Group>
                                <Form.Label>Switch Id</Form.Label>
                                <Form.Control
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={linkPage.selectedGlobalSwitchId}
                                  name={`linkPages.${i}.selectedGlobalSwitchId`}
                                  disabled={true}
                                />
                                <Form.Text className="text-small text-danger">
                                  {errors.linkPages &&
                                    touched.linkPages &&
                                    errors.linkPages[i] &&
                                    touched.linkPages[i] &&
                                    errors.linkPages[i]
                                      .selectedGlobalSwitchId &&
                                    touched.linkPages[i]
                                      .selectedGlobalSwitchId &&
                                    errors.linkPages[i].selectedGlobalSwitchId}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group>
                                <Form.Label>Change Order</Form.Label>
                                <Form.Control
                                  type="number"
                                  step={1}
                                  min={1}
                                  max={unprocessedFaxDetails.noOfPages}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={linkPage.pageIndex || ""}
                                  name={`linkPages.${i}.pageIndex`}
                                ></Form.Control>
                                <Form.Text className="text-small text-danger">
                                  {errors.linkPages &&
                                    touched.linkPages &&
                                    errors.linkPages[i] &&
                                    touched.linkPages[i] &&
                                    errors.linkPages[i].pageIndex &&
                                    touched.linkPages[i].pageIndex &&
                                    errors.linkPages[i].pageIndex}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group>
                                <Form.Label>Additional Pages</Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={linkPage.additionalPages || ""}
                                  name={`linkPages.${i}.additionalPages`}
                                ></Form.Control>
                                <Form.Text className="text-small text-danger">
                                  {errors.linkPages &&
                                    touched.linkPages &&
                                    errors.linkPages[i] &&
                                    touched.linkPages[i] &&
                                    errors.linkPages[i].additionalPages &&
                                    touched.linkPages[i].additionalPages &&
                                    errors.linkPages[i].additionalPages}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group>
                                <Form.Label>Orientation</Form.Label>
                                <br />
                                <Form.Check
                                  type="checkbox"
                                  value={linkPage.isUpsideDown}
                                  name={`linkPages.${i}.isUpsideDown`}
                                  label="Is Upside Down"
                                  style={{ float: "left" }}
                                  onChange={handleChange}
                                  checked={linkPage.isUpsideDown}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        ))}
                        <hr />
                      </>
                    )}
                  />
                  <Row>
                    <Col md={6} className="d-grid gap-2">
                      <Button
                        id="unprocessed-fax-btn-link"
                        block
                        variant="success"
                        type="submit"
                        disabled={isSubmitting || this.state.disableLinking}
                      >
                        Link Switch(es)
                      </Button>
                    </Col>
                    <Col md={6}>
                      <DiscardFax
                        disallowEditing={isSubmitting}
                        discardFax={this.props.discardFax.bind(this)}
                        faxId={faxInfoId}
                      />
                    </Col>
                  </Row>
                  <MemberSwitchList
                    setSelectedSwitches={this.onChangeLinkSwitches}
                    values={values}
                    setValues={setValues}
                  />
                  <Form>
                    <Form.Group>
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="notes"
                        value={note}
                        onChange={this.handleNoteChange}
                      />
                    </Form.Group>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    {noteObject?.uflNoteUuid?.length > 0 ? (
                      <>
                        <Button
                          variant="primary"
                          onClick={this.handleSaveOrUpdateNote}
                          style={{ marginRight: "10px" }}
                          disabled={note.length === 0}
                        >
                          Update Note
                        </Button>
                        <Button
                          variant="danger"
                          onClick={this.handleDeleteNote}
                        >
                          Delete Note
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={this.handleSaveOrUpdateNote}
                        disabled={note.length === 0}
                      >
                        Save Note
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </Col>

          <Col md={7}>
            {
              <SecurePdfViewer
                pdfHeight={705}
                displayToolBar={false}
                url={`/pdf/prescriber-inbound-fax/${faxInfoId}`}
              />
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    unprocessedFaxDetailsStatus:
      state.unprocessedFaxDetails.unprocessedFaxDetailsStatus,
    unprocessedFaxDetails: state.unprocessedFaxDetails.unprocessedFaxDetails,
    linkRequestStatus: state.unprocessedFaxDetails.linkRequestStatus,
    errorMessage: state.unprocessedFaxDetails.errorMessage,
    discardRequestCompleted:
      state.unprocessedFaxDetails.discardRequestCompleted,
    faxPrioritySuccess: state.unprocessedFaxDetails.faxPrioritySuccess,
    faxPriorityError: state.unprocessedFaxDetails.faxPriorityError,
    saveNoteSuccess: state.unprocessedFaxDetails.saveNoteSuccess,
    saveNoteError: state.unprocessedFaxDetails.saveNoteError,
    saveNoteRequested: state.unprocessedFaxDetails.saveNoteRequested,
    updateNoteRequested: state.unprocessedFaxDetails.updateNoteRequested,
    updateNoteSuccess: state.unprocessedFaxDetails.updateNoteSuccess,
    updateNoteError: state.unprocessedFaxDetails.updateNoteError,
    noteObject: state.unprocessedFaxDetails.noteObject,
    savedNote: state.unprocessedFaxDetails.savedNote,
    updatedNote: state.unprocessedFaxDetails.updatedNote,
    deleteNoteSuccess: state.unprocessedFaxDetails.deleteNoteSuccess,
    deleteNoteFailure: state.unprocessedFaxDetails.deleteNoteFailure,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    linkUnprocessedFaxToSwitch: bindActionCreators(
      linkUnprocessedFaxToSwitch,
      dispatch
    ),
    resetLinkRequestStatus: bindActionCreators(
      resetLinkRequestStatus,
      dispatch
    ),
    fetchUnprocessedFaxDetails: bindActionCreators(
      fetchUnprocessedFaxDetails,
      dispatch
    ),
    fetchNoteForUnlinkedFax: bindActionCreators(
      fetchNoteForUnlinkedFax,
      dispatch
    ),
    discardFax: bindActionCreators(discardFax, dispatch),
    setPriorityOfUnprocessedFax: bindActionCreators(
      setPriorityOfUnprocessedFax,
      dispatch
    ),
    saveNote: bindActionCreators(saveNote, dispatch),
    resetNoteSuccess: bindActionCreators(resetNoteSuccess, dispatch),
    updateNote: bindActionCreators(updateNote, dispatch),
    resetUpdateNoteSuccess: bindActionCreators(
      resetUpdateNoteSuccess,
      dispatch
    ),
    deleteNote: bindActionCreators(deleteNote, dispatch),
    resetDeleteNoteSuccess: bindActionCreators(
      resetDeleteNoteSuccess,
      dispatch
    ),
    resetDiscardedFax: bindActionCreators(resetDiscardedFax, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UnprocessedFaxDetails));
